/* eslint-disable jsx-a11y/anchor-is-valid */
import CryptoJS from "crypto-js";
import React, { useEffect, useState, useCallback } from "react";
import { CiLogout, CiSettings } from "react-icons/ci";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaSyncAlt } from "react-icons/fa";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../../setup/RootReducer";
import { getAuth } from "../../../../../utils/AuthHelper";
import { CheckIsNull, toAbsoluteUrl } from "../../../../../utils/CommonHelper";
import { NAVBAR } from "../../../../constant/Navbar";
import { IAgentMemberDetailResponse } from "../../models/response/CommonResponseModel";
import * as actions from "../../redux/CommonAction";
import Modal from "../Modal";

const LayoutProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { agentBalance } = useSelector((state: RootState) => state.common);

  const handleToggle = (title?: string) => {
    if (!CheckIsNull(title)) {
      navigate(`/${title}`);
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    dispatch<any>(actions.fetchAgentBalance({ action: "agentbalance" }));
  }, [dispatch]);

  const handleRefresh = () => {
    if (!isButtonDisabled) {
      setIsButtonDisabled(true);
      dispatch<any>(actions.fetchAgentBalance({ action: "agentbalance" }));
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 1000);
    }
  };

  useEffect(() => {
    const handleTimeOut = () => {
      setIsResponseModalOpen(true);
    };

    window.addEventListener("timeout", handleTimeOut);

    return () => window.removeEventListener("timeout", handleTimeOut);
  }, []);

  const handleRedirect = () => {
    dispatch<any>(
      actions.fetchAgentMemberDetail({ action: "agentredirectmember" })
    ).then((res?: IAgentMemberDetailResponse) => {
      if (res?.code === "0") {
        const encodeCredential = encodeURIComponent(
          btoa(
            CryptoJS.AES.encrypt(
              JSON.stringify({
                username: res?.username,
                password: res?.password,
              }),
              "lott4896#!@lott4896"
            ).toString()
          )
        );
        window.dispatchEvent(new CustomEvent("authEvent"));
        setTimeout(() => {
          window.location.href = `https://m.member.lotto4896.com/${encodeCredential}`;
        }, 500);
      }
    });
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header>
        <div className="flex items-center justify-between p-3 bg-slate-950">
          <RxHamburgerMenu
            className="cursor-pointer size-5"
            onClick={() => handleToggle()}
          />
          <div className="font-semibold">
            {!CheckIsNull(window.location.pathname.split("/")[1])
              ? intl.formatMessage({
                  id: window.location.pathname.split("/")[1],
                })
              : null}
          </div>
          <div className="flex items-center">
            {agentBalance?.balance ?? intl.formatMessage({ id: "loading" })}
            <button
              onClick={handleRefresh}
              aria-label="Refresh Balance"
              disabled={isButtonDisabled}
              style={{
                background: "none",
                border: "none",
                cursor: isButtonDisabled ? "not-allowed" : "pointer",
                padding: 0,
                display: "flex",
                alignItems: "center",
                marginLeft: "8px",
                color: isButtonDisabled ? "#999" : "#007bff", // Adjust color when disabled
              }}
              onMouseOver={
                (e) =>
                  !isButtonDisabled && (e.currentTarget.style.color = "#0056b3") // Hover color
              }
              onMouseOut={
                (e) =>
                  !isButtonDisabled && (e.currentTarget.style.color = "#007bff") // Default color
              }
            >
              <FaSyncAlt size={16} />
            </button>
          </div>
        </div>
        <div
          className={`fixed inset-y-0 left-0 z-40 w-64 transform transition-transform bg-gray-800 ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="flex flex-col h-full">
            <div className="p-4 my-4">
              <img src={toAbsoluteUrl("/media/logo.png")} className="w-full" />
            </div>
            <nav className="flex-1 py-4 space-y-2">
              {NAVBAR.filter(
                (nav) =>
                  nav.title !== "commissionSetting" ||
                  getAuth()?.plancode === "planb"
              ).map((nav) => (
                <a
                  className={`flex items-center px-4 py-2 rounded font-semibold ${
                    window.location.pathname.includes(nav.title)
                      ? "bg-gray-700 text-white"
                      : "hover:bg-gray-700"
                  }`}
                  onClick={() => handleToggle(nav.title)}
                  key={nav.title}
                >
                  {nav.icon}
                  {intl.formatMessage({ id: nav.title })}
                </a>
              ))}
            </nav>
            <div className="border border-gray-600" />
            <div className="py-2">
              <a
                className={`flex items-center px-4 py-2 rounded font-semibold ${
                  window.location.pathname.includes("setting")
                    ? "bg-gray-700 text-white"
                    : "hover:bg-gray-700"
                }`}
                onClick={() => handleToggle("setting")}
              >
                <CiSettings className="mr-2 size-6" />
                {intl.formatMessage({ id: "setting" })}
              </a>
              <a
                className="flex items-center px-4 py-2 font-semibold rounded hover:bg-gray-700"
                onClick={handleRedirect}
              >
                <CiLogout className="mr-2 size-6" />
                {intl.formatMessage({ id: "redirectToMember" })}
              </a>
            </div>
          </div>
        </div>
      </header>
      {isOpen && (
        <div
          onClick={() => handleToggle()}
          className="fixed inset-0 bg-black opacity-50"
        />
      )}
      <main className="flex flex-col flex-1 p-4">
        <Modal
          style={{ zIndex: "999999" }}
          isOpen={isResponseModalOpen}
          onClose={() => {
            setIsResponseModalOpen(false);
            window.dispatchEvent(new CustomEvent("authEvent"));
          }}
          title={intl.formatMessage({ id: "alert" })}
        >
          <div className="pt-2 pb-4 mb-4 border-b border-gray-700">
            {intl.formatMessage({ id: "sessionTimeout" })}
          </div>
          <div className="flex justify-end">
            <button
              className="px-4 py-1 font-semibold rounded bg-slate-400 hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-600"
              type="button"
              onClick={() => {
                setIsResponseModalOpen(false);
                window.dispatchEvent(new CustomEvent("authEvent"));
                navigate("/");
              }}
            >
              {intl.formatMessage({ id: "ok" })}
            </button>
          </div>
        </Modal>
        {children}
      </main>
      <footer></footer>
    </div>
  );
};

export default LayoutProvider;
